import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
window.Buffer = window.Buffer || require("buffer").Buffer;

const isWeChat = navigator.userAgent.includes('WeChat') || navigator.userAgent.includes('MicroMessenger')
if (isWeChat) {
    alert('當前瀏覽器不支持本程式，請用其他瀏覽器打開！\n推薦使用Chrome瀏覽器.')
} else {
    if (window.Referrer === undefined) {
        let refId = (new URL(window.location.href)).searchParams.get('id');
        console.log('Referrer Id:', refId);
        if (refId === '' || refId === null || refId === undefined) {
            refId = null;
        }

        window.Referrer = refId;
    }

    createApp(App).use(store).use(router).mount('#app')
}