import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index2',
    component: () => import('../views/index/Index')
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../views/index/Index')
  },
  {
    path: '/Index_tc',
    name: 'Index_tc',
    component: () => import('../views/index_tc/Index_tc')
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Dashboard')
  },
  {
    path: '/Dashboard_tc',
    name: 'Dashboard_tc',
    component: () => import('../views/dashboard_tc/Dashboard_tc')
  },
  {
    path: '/Deposit',
    name: 'Deposit',
    component: () => import('../views/deposit/Deposit')
  },
  {
    path: '/Deposit_tc',
    name: 'Deposit_tc',
    component: () => import('../views/deposit_tc/Deposit_tc')
  },
  {
    path: '/Details',
    name: 'Details',
    component: () => import('../views/details/Details')
  },
  {
    path: '/Details_tc',
    name: 'Details_tc',
    component: () => import('../views/details_tc/Details_tc')
  },
  {
    path: '/Mapping',
    name: 'Mapping',
    component: () => import('../views/mapping/Mapping')
  },
  {
    path: '/Mapping_tc',
    name: 'Mapping_tc',
    component: () => import('../views/mapping_tc/Mapping_tc')
  },
  {
    path: '/Publicize',
    name: 'Publicize',
    component: () => import('../views/publicize/Publicize')
  },
  {
    path: '/Publicize_tc',
    name: 'Publicize_tc',
    component: () => import('../views/publicize_tc/Publicize_tc')
  },
  {
    path: '/Release',
    name: 'Release',
    component: () => import('../views/release/Release')
  },
  {
    path: '/Release_tc',
    name: 'Release_tc',
    component: () => import('../views/release_tc/Release_tc')
  },
  {
    path: '/Sell',
    name: 'Sell',
    component: () => import('../views/sell/Sell')
  },
  {
    path: '/Sell_tc',
    name: 'Sell_tc',
    component: () => import('../views/sell_tc/Sell_tc')
  },
  {
    path: '/Transfer',
    name: 'Transfer',
    component: () => import('../views/transfer/Transfer')
  },
  {
    path: '/Transfer_tc',
    name: 'Transfer_tc',
    component: () => import('../views/transfer_tc/Transfer_tc')
  },
  {
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import('../views/withdraw/Withdraw')
  },
  {
    path: '/Withdraw_tc',
    name: 'Withdraw_tc',
    component: () => import('../views/withdraw_tc/Withdraw_tc')
  },
  {
    path: '/History',
    name: 'History',
    component: () => import('../views/history/History')
  },
  {
    path: '/History_tc',
    name: 'History_tc',
    component: () => import('../views/history_tc/History_tc')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === "Index" || to.name === "Index_tc" || to.name === "Dashboard" || to.name === "Dashboard_tc") {
    next();
  } else {
    if (localStorage.islogin === "false") {
      if (to.name.includes('_tc')) {
        router.push({ name: "Index_tc" })
      } else {
        router.push({ name: "Index" })
      }
    } else {
      next();
    }
  }
})

router.afterEach((to, from, next) => {
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});

export default router
