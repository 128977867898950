import { createStore } from 'vuex'

export default createStore({
  state: {
    DPI: "-",
    ExchangeRate: "-"
  },
  getters: {
  },
  mutations: {
    getDPI(store, str) {
      this.state.DPI = str;
    },
    getExchangeRate(store, str) {
      this.state.ExchangeRate = str;
    }
  },
  actions: {
    getDPI(store, str) {
      this.commit('getDPI', str)
    },
    getExchangeRate(store, str) {
      this.commit('getExchangeRate', str)
    }
  },
  modules: {
  }
})
